<template>
  <div>
     <iframe title="print_frame" name="print_frame" id="blob-src-test" style="width: 100%;" :style="'height:' + height +'px'" >
     </iframe>
   </div>
  </template>
  <script>
  import MyJobApp from '@/api/MyJobApp.js'
  export default {
    data() {
      return {
        fileURL: '',
        height: window.innerHeight
      };
    },
    watch: {
    },
    created() {
      window.addEventListener("resize", this.resizeWindowHandler);
    },
    mounted() {
      this.getInvoiceDetail()
    },
    methods: {
      resizeWindowHandler() {
        this.screenWidth = window.innerWidth
      },
      getInvoiceDetail () {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
        MyJobApp.downloadPayment(
          this.$route.params.hashId,
          response => {
            var blobUrl = window.URL.createObjectURL(response)
            this.fileURL = blobUrl
            document.querySelector("iframe").src = blobUrl;
            setTimeout(function () {
              window.frames['print_frame'].focus()
              window.frames['print_frame'].print()
            }, 1000)
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          () => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
      },
    },
  };
  </script>
  <style scoped>
  </style>
  
  